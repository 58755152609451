.service-image {
    margin: 15px;
}

.service-image img {
    overflow: hidden;
    width: 100%;
}

.service-card-content {
    display: flex;
    padding: 0 15px 0 15px;
    justify-content: space-between;
    margin-bottom: -1.5rem;
}


.service-card-button h3 {
    user-select: none;
    /* Prevents text selection */
}

.service-card-button h3 .MuiSvgIcon-root {
    cursor: pointer;
    /* Changes cursor to indicate it's clickable */
    color: #213E60;
    /* Optional: set icon color to match the theme */
    transition: color 0.3s ease;
}

.service-card-button {
    padding: 0 15px 15px 15px;
}


/* Overlay for the popup */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Popup content container */
.popup-content {
    position: relative;
    width: 90%;
    max-width: 600px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    text-align: center;
}

/* Responsive popup image */
.popup-image {
    width: 90%;
    height: auto;
    border-radius: 8px;
}



/* Enhanced close button */
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #d9534f;
    /* Add background color for visibility */
    color: #fff;
    /* White text for contrast */
    font-size: 2rem;
    /* Increase font size */
    font-weight: bolder;
    /* Extra bold text */
    padding: 5px 10px;
    /* Padding for more clickable area */
    border-radius: 50%;
    /* Rounded button for aesthetic */
    border: none;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    /* Subtle shadow */
    cursor: pointer;
    outline: none;
    transition: transform 0.2s, background-color 0.2s;
    /* Smooth transitions */
}

.close-button:hover {
    transform: scale(1.15);
    /* Enlarge on hover for emphasis */
    background-color: #c9302c;
    /* Darken color slightly on hover */
}

/* Make popup responsive on smaller screens */
@media (max-width: 600px) {
    .popup-content {
        width: 90%;
        padding: 15px;
    }

    .close-button {
        font-size: 1.75rem;
        /* Adjust font size on small screens */
    }
}

.close-button-form {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    background-color: #d9534f;
}