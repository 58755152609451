.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #213E60;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    z-index: 1000;
}

.navbar-item {
    display: flex;
    width: 80%;
}

/* .navbar-element {
    display: flex;
} */


.navbar-logo img {
    padding: 5px 0px 5px 0px;
    width: 60px;
}


.nav-item-desktop {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.nav-item-desktop ul {
    display: flex;
    align-items: center;

}

.nav-item-desktop ul li {
    text-decoration: none;
    padding-left: 5rem;
    color: #206572;
    color: white;
    font-weight: 500;
    cursor: pointer;
    list-style: none;
}


.nav-item-desktop ul li .active {
    position: relative;
    font-weight: 900;
    font-size: 1.1rem;
}

/* Underline effect on active link */
.nav-item-desktop ul li .active::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -4px; /* Adjust the underline position */
    width: 100%;
    height: 2px;
    background-color: #ffffff; /* Gold color for the underline */
    transition: width 0.3s ease-in-out;
}

/* Hover effect */
.nav-item-desktop ul li:hover::after {
    width: 100%;
}



.nav-item-mobile {
    display: none;
}

.hamburger-icon {
    display: none;
    margin-left: auto;
}


.mobile-menu-icon {
    margin-right: 0.5rem;
    font-size: 2rem;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
}

.whatsapp-button {
    position: fixed;

    bottom: 30px;
    right: 20px;
    background-color: #25d366;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    transition: transform 0.3s;
}

.whatsapp-button:hover {
    transform: scale(1.1);
}

.whatsapp-button img {
    width: 40px;
    height: 40px;
}


@media (max-width: 1900px) {

    .nav-item-desktop ul li {
        font-size: 1rem;

    }
}

@media (max-width: 1120px) {

    .nav-item-desktop ul li {
        padding-left: 4rem;
        font-size: 1rem;

    }
}

@media (max-width: 1020px) {

    .nav-item-desktop ul li {
        padding-left: 3rem;
        font-size: 0.9rem;

    }
}

@media (max-width: 899px) {

    .navbar-item {
        display: flex;
        width: 90%;
    }

    .nav-item-desktop {
        display: none;
    }

    .hamburger-icon {
        display: flex;
        margin-left: auto;
    }

    .nav-item-mobile {
        display: block;
    }

    .nav-item-mobile ul{
        position: absolute;
        top: 54px;
        right: 0;
        background-color: #213E60;
        width: 100%;
    }

    .nav-item-mobile ul li {
        padding: 1rem;
        list-style: none;
        font-size: 1.2rem;
        color: #ffffff;
        font-weight: bold;
        cursor: pointer;
        border-bottom: 2px solid white;
    }
}
