.hero {
    margin-top: 8rem;
  }
  
  /* Hero section layout adjustments */
  .hero-item {
    padding: 10rem 0;
    gap: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .hero-text {
    font-size: 1.5rem;
    text-align: left;
  }
  
  /* Paragraph styling */
  .hero-paragraph {
    text-align: justify;
  }
  
  /* Custom styles for the headings */
  .hero-heading {
    color: #213E60;
  }
  
  .hero-name {
    color: #206572;
  }
  
  .hero-role {
    color: red;
    font-weight: bold;
  }
  
  /* Button Styling */
  .hero-btn {
    margin-top: 2rem;
  }
  
  /* Responsive Design with Media Queries */
  @media only screen and (max-width: 768px) {
    .hero-text {
      font-size: 1.5rem;
      text-align: center;
    }
  
    .hero-paragraph {
      font-size: 1.2rem;
    }
  
    
  
    /* Adjust button size for smaller screens */
    .hero-btn button {
      font-size: 1rem;
      width: 100%;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .hero {
      margin-top: 5rem;
    }
  
    .hero-text {
      font-size: 1.1rem;
    }
  
    .hero-paragraph {
      font-size: 1.1rem;
    }

    .hero-btn button {
      font-size: 1.2rem;
      width: 100%;
    }
  }
  
  /* Medium-sized devices (tablet) adjustments */
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .hero-text {
      font-size: 1.3rem;
      text-align: left;
    }
  
    .hero-paragraph {
      font-size: 1rem;
    }
  
   
  }
  